@import 'config';
@import 'demo/highlight';
@import 'demo/examples';

.card-sponsor {
  background: #dbe7f6 no-repeat center/100% 100%;
  border-color: #548ed2;
  min-height: 316px;
}

body.no-transitions * {
  transition: none !important;
}

.dropdown-menu-demo {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 0;
  margin-bottom: 1rem;
}

.demo-icon-preview {
  position: sticky;
  top: 0;

  svg,
  i {
    width: 15rem;
    height: 15rem;
    font-size: 15rem;
    stroke-width: 1.5;
    margin: 0 auto;
    display: block;

    @include media-breakpoint-down(sm) {
      width: 10rem;
      height: 10rem;
      font-size: 10rem;
    }
  }
}

.demo-icon-preview-icon {
  pre {
    margin: 0;
    user-select: all;
  }
}

.demo-dividers {
  > p {
    opacity: 0.2;
    user-select: none;
  }
}

$demo-icon-size: 4rem;
.demo-icons-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -2px -1px 0;
  list-style: none;

  > * {
    flex: 1 0 $demo-icon-size;
  }
}

.demo-icons-list-wrap {
  overflow: hidden;
}

.demo-icons-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  text-align: center;
  padding: 0.5rem;
  border-right: 1px solid var(--#{$prefix}border-color);
  border-bottom: 1px solid var(--#{$prefix}border-color);
  color: inherit;
  cursor: pointer;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }

  &:hover {
    text-decoration: none;
  }
}

//
// Settings
//
.settings-btn {
  position: fixed;
  right: -1px;
  top: 10rem;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: $shadow;
}

.settings-scheme {
  display: inline-block;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  position: relative;
  border: 1px solid var(--#{$prefix}border-color);
  box-shadow: $shadow;

  &-light {
    background: linear-gradient(135deg, $white 50%, $light 50%);
  }
  &-mixed {
    background-image: linear-gradient(135deg, $dark 50%, #fff 50%);
  }
  &-transparent {
    background: $light;
  }
  &-dark {
    background: $dark;
  }
  &-colored {
    background-image: linear-gradient(135deg, var(--#{$prefix}primary) 50%, $light 50%);
  }
}

:root {
  --tmr-bootstrap-border-color: #dee2e6;
  --tmr-white: #eee;
  --tmr-table-header: #54667a;
  --tmr-row-divider-color: #3490dc;
  --tmr-stripped-row-background-color: rgba(0, 0, 0, 0.05);
}

/*-- ==============================================================
Screen smaller than 760px and iPads.
============================================================== */

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  [data-content]:before {
    content: attr(data-content);
  }

  /* Force table to not be like tables anymore */
  .table-mobile-responsive,
  .table-mobile-responsive thead,
  .table-mobile-responsive tbody,
  .table-mobile-responsive th,
  .table-mobile-responsive td,
  .table-mobile-responsive tr {
    display: block;
  }

  .table-mobile-responsive.text-center {
    text-align: left !important;
  }
  .table-mobile-responsive caption {
    width: max-content;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .table-mobile-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table-mobile-responsive > :not(:first-child) {
    border-top: none;
  }

  .table-mobile-responsive > :not(caption) > * > * {
    border-color: var(--tmr-bootstrap-border-color);
  }

  .table-mobile-responsive tr:not(.bg-light-blue) {
    border-bottom: 2px solid var(--tmr-row-divider-color);
  }

  /* Default layout */
  .table-mobile-responsive td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid var(--tmr-white);
    position: relative;
    padding-left: 50%;
    padding-top: 1.5rem !important;
  }

  .table-mobile-responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
    color: var(--tmr-table-header);
  }

  /* Sided layout */
  .table-mobile-responsive.table-mobile-sided > :not(:first-child) {
    border-top: none;
  }

  .table-mobile-responsive.table-mobile-sided > :not(caption) > * > * {
    border-color: var(--bs-table-border-color);
  }
  .table-mobile-responsive.table-mobile-sided td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid var(--tmr-white);
    position: relative;
    padding-left: 50%;
    padding-top: 0px !important;
    display: flex;
    justify-content: flex-end;
  }
  .table-mobile-responsive.table-mobile-sided td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
    color: var(--tmr-table-header);
  }

  /* Styleless */
  .table-mobile-responsive.table-mobile-styleless tr:not(.bg-light-blue) {
    border-bottom: none !important;
  }

  /* Stripped rows */
  .table-mobile-responsive.table-mobile-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: var(--tmr-stripped-row-background-color) !important;
  }
}
