$code-green: #b5f4a5;
$code-yellow: #ffe484;
$code-purple: #d9a9ff;
$code-red: #ff8383;
$code-blue: #93ddfd;
//$code-white: $white;
$code-gray: #a0aec0;

pre.highlight,
.highlight pre {
  max-height: 30rem;
  margin: 1.5rem 0;
  overflow: auto;
  border-radius: $border-radius;
  @include scrollbar;
}

.highlight {
  margin: 0;

  code > * {
    margin: 0 !important;
    padding: 0 !important;
  }

  .dl {
    color: #4070a0
  }

  .c {
    color: #727272
  }

  .c1 {
    color: #727272
  }

  .ch {
    font-style: italic;
    color: #60a0b0
  }

  .cm {
    color: #727272
  }

  .cp {
    color: #008085
  }

  .cpf {
    color: #007020
  }

  .cs {
    color: #727272
  }

  .gd {
    background-color: #fcc;
    border: 1px solid #c00
  }

  .ge {
    font-style: italic
  }

  .gh {
    color: #030
  }

  .gi {
    background-color: #cfc;
    border: 1px solid #0c0
  }

  .gl {
    text-decoration: underline
  }

  .go {
    color: #aaa
  }

  .gp {
    color: #009
  }

  .gr {
    color: #f00
  }

  .gs {
    font-weight: 700
  }

  .gt {
    color: #9c6
  }

  .gu {
    color: #030
  }

  .il {
    color: #c24f19
  }

  .k {
    color: #069
  }

  .kc {
    color: #069
  }

  .kd {
    color: #069
  }

  .kn {
    color: #069
  }

  .kp {
    color: #069
  }

  .kr {
    color: #069
  }

  .kt {
    color: #078
  }

  .m {
    color: #c24f19
  }

  .mf {
    color: #c24f19
  }

  .mb {
    color: #40a070
  }

  .mh {
    color: #c24f19
  }

  .mi {
    color: #c24f19
  }

  .mo {
    color: #c24f19
  }

  .na {
    color: #006ee0
  }

  .nb {
    color: #366
  }

  .nc {
    color: #168174
  }

  .nd {
    color: #6b62de
  }

  .ne {
    color: #c00
  }

  .nf {
    color: #b715f4
  }

  .ni {
    color: #727272
  }

  .nl {
    color: #6b62de
  }

  .nn {
    color: #007ca5
  }

  .no {
    color: #360
  }

  .nt {
    color: #2f6f9f
  }

  .nv {
    color: #033
  }

  .o {
    color: #555
  }

  .ow {
    color: #000
  }

  .s {
    color: #d73038
  }

  .s1 {
    color: #c30
  }

  .s2 {
    color: #c30
  }

  .sa {
    color: #4070a0
  }

  .sb {
    color: #c30
  }

  .sc {
    color: #c30
  }

  .sd {
    font-style: italic;
    color: #c30
  }

  .se {
    color: #c30
  }

  .sh {
    color: #c30
  }

  .si {
    color: #a00
  }

  .sr {
    color: #337e7e
  }

  .ss {
    color: #fc3
  }

  .sx {
    color: #c30
  }

  .w {
    color: #bbb
  }

  .language-bash::before,.language-sh::before {
    color: #009;
    content: "$ ";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  .language-bash .m,.language-sh .m {
    color: inherit
  }

  .language-powershell::before {
    color: #009;
    content: "PM> ";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
}
