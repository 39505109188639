.example {
  padding: 2rem;
  margin: 1rem 0 2rem;
  border: 1px solid $border-color;
  border-radius: 3px 3px 0 0;
  position: relative;

  min-height: 12rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.example-centered {
  justify-content: center;

  .example-content {
    flex: 0 auto;
  }
}

.example-content {
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $body-color;
  flex: 1;
  max-width: 100%;

  .page-header {
    margin-bottom: 0;
  }
}

.example-bg {
  background: $body-bg;
}

.example-code {
  margin: 2rem 0;
  border: 1px solid $border-color;
  border-top: none;

  pre {
    margin: 0;
    border: 0;
    border-radius: 0 0 3px 3px;
  }

  .example + & {
    margin-top: -2rem;
  }
}

.example-column {
  margin: 0 auto;

  > .card:last-of-type {
    margin-bottom: 0;
  }
}

.example-column-1 {
  max-width: 26rem;
}

.example-column-2 {
  max-width: 52rem;
}

.example-modal-backdrop {
  background: $modal-backdrop-bg;
  opacity: $modal-backdrop-opacity;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 2px 2px 0 0;
}

@include dark-mode {
  .example {
    background-color: $dark-mode-darken;
    border-color: $dark-mode-border-color;
  }

  .example-content {
    color: $dark-mode-text;
  }

  .example-code {
    border-color: $dark-mode-border-color;
    border-top: none;
  }
}